import Grid from 'antd/lib/grid';
import classNames from 'classnames';

import CarouselGames from 'src/components/CarouselGames';
import Divider from 'src/components/Divider';
import MainBanners from 'src/components/MainBanners';
import SectionPremium from 'src/components/SectionPremium';
import { useGetBannerList, useGetGameList } from 'src/hooks';
import { IBanner } from 'src/interface/Banners';

import classes from './index.module.less';

const Home = () => {
	const { xs } = Grid.useBreakpoint();

	const { data: dataB, isLoading: isLoadingBanners } = useGetBannerList();
	const dataBanners: IBanner[] = dataB?.data?.data;

	const { data, isLoading: isLoadingGames } = useGetGameList({ limit: 10 });
	const dataGames = data?.data?.data;

	// const gameSlug = 'evermore-knights';
	// const { data: dataGSIList, isLoading: isLoadingGameStoreItemList } = useGetGameStoreItemList(
	// gameSlug,
	// {
	// page: 1,
	// limit: 10,
	// },
	// );
	// const dataGameStoreItemList: IStoreItem[] = dataGSIList?.data?.data;

	// const { data: dataGameStoreItemListWithPrices, isLoading: isLoadingGameStoreItemListPrices } =
	// useGetGameStoreItemListPrices(dataGameStoreItemList, dataGameStoreItemList?.length > 0);

	// const { data: dataMIList, isLoading: isLoadingMarketplaceItemList } = useGetMarketplaceItemList({
	// page: 1,
	// limit: 10,
	// });
	// const dataMarketplaceItemList: IMarketplaceItem[] = dataMIList?.data?.data;

	// // const { data: dataT, isLoading: isLoadingTrendingItems } = useGetTrendingItems();
	// // const dataTrendingItems = dataT?.data?.data;
	// const dataTrendingItems = dataMarketplaceItemList;
	// const isLoadingTrendingItems = isLoadingMarketplaceItemList;

	// // const { data: dataFS, isLoading: isLoadingFlashSaleItems } = useGetFlashSaleItems();
	// // const dataFlashSaleItems = dataFS?.data?.data;
	// const dataFlashSaleItems = dataGameStoreItemListWithPrices;
	// const isLoadingFlashSaleItems = isLoadingGameStoreItemList || isLoadingGameStoreItemListPrices;

	// // const { data: dataBS, isLoading: isLoadingBestSellerItems } = useGetBestSellerItems();
	// // const dataBestSellerItems = dataBS?.data?.data;
	// const dataBestSellerItems = dataGameStoreItemListWithPrices;
	// const isLoadingBestSellerItems = isLoadingGameStoreItemList || isLoadingGameStoreItemListPrices;

	return (
		<div className={classes['home-container']}>
			<MainBanners
				className={classes['main-banners']}
				items={dataBanners}
				isLoading={isLoadingBanners}
			/>
			<div className={classes['carousel-games-section']}>
				<div className="container">
					<h1 className={classes.title}>
						Play Hundreds of High-Quality <span>WEB3</span> Games
					</h1>
				</div>
				<CarouselGames items={dataGames} isLoading={isLoadingGames} />
			</div>
			{/* <div className={classNames('container', classes['floor-section'])}>
				<FloorProducts
					title="Trending NFT"
					itemType="marketplace"
					items={dataTrendingItems}
					isLoading={isLoadingTrendingItems}
					moreLink="/marketplace"
				/>
			</div> */}
			{/* <div className={classNames('container', classes['floor-section'])}>
				<FloorProducts
					title="Flash Sale Game Item"
					itemType="store"
					gameSlug={gameSlug}
					items={dataFlashSaleItems}
					isLoading={isLoadingFlashSaleItems}
					moreLink="/store"
				/>
			</div> */}
			{/* <div className={classNames('container', classes['floor-section'])}>
				<FloorProducts
					title="Best Sellers"
					itemType="store"
					gameSlug={gameSlug}
					items={dataBestSellerItems}
					isLoading={isLoadingBestSellerItems}
					moreLink="/store"
				/>
			</div> */}
			<div className={classNames({ container: !xs })}>
				<Divider width={4} color="#2B2B2B" />
			</div>
			<div className={classNames(classes['premium-section'])}>
				<SectionPremium />
			</div>
		</div>
	);
};

export default Home;
