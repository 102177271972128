import { dehydrate, QueryClient } from 'react-query';
import { GetStaticProps } from 'next';

import MetaTags from 'src/components/HeadMeta';
import Spinner from 'src/components/Spinner';
import global from 'src/constants/global';
import Home from 'src/containers/Home';
import Maintenance from 'src/containers/Maintenance';
import { queryKeyBannerList, queryKeyGameList } from 'src/hooks';
import { useGetMaintenanceStatus } from 'src/hooks/useGeneral';
import { wrapper } from 'src/redux/store';
import { fetchAPI } from 'src/utils/fetch-api';

const HomePage = () => {
	const meta = {
		title: 'CreoPlay: Play the Best WEB3 Games',
		description:
			'Play hundreds of high-quality WEB3 games on CreoPlay, the ultimate blockchain gaming hub for developers and gamers.',
		url: '/',
	};

	const { data, isFetching } = useGetMaintenanceStatus();
	const isMaintenance =
		data?.data?.data?.maintenanceStatus === true || data?.data?.data?.maintenanceStatus === 'true';

	if (isFetching || isMaintenance === undefined) {
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					zIndex: 9999,
					width: '100vw',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: '#060606',
				}}
			>
				<Spinner />
			</div>
		);
	}

	if (isMaintenance) {
		return <Maintenance />;
	}

	return (
		<>
			<MetaTags {...meta} />
			<Home />
		</>
	);
};

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(() => async () => {
	const queryClient = new QueryClient();

	let notFound = false;

	try {
		await queryClient.prefetchQuery([queryKeyBannerList, null], () =>
			fetchAPI({
				endpoint: '/home/banners',
			}),
		);

		const params = { page: 1, limit: 10 };
		await queryClient.prefetchQuery([queryKeyGameList, params], () =>
			fetchAPI({
				endpoint: '/home/games',
				params: params,
			}),
		);
	} catch (err: any) {
		if (err?.status === 400) {
			return {
				notFound: true,
			};
		} else {
			throw err;
		}
	}

	if (notFound) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			dehydratedState: dehydrate(queryClient),
		},
		revalidate: global.REVALIDATE_EXP,
	};
});

export default HomePage;
