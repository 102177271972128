import { Col, Row } from 'antd/lib/grid';

import ImageC from 'src/components/Image';

import classes from './index.module.less';

const Maintenance = () => {
	return (
		<div className={classes.container}>
			<div className="container">
				<Row className="w-100" gutter={[0, 15]}>
					<Col xs={24} sm={12} className="d-flex flex-column justify-content-center">
						<div className={classes.info}>
							<h1>We are tidying up!</h1>
							<h2>Sorry for the inconvenience!</h2>
							<h3>
								Currently updating servers to improve service. <br />
								Thank you for your patience!
							</h3>
						</div>
					</Col>
					<Col xs={24} sm={12}>
						<div className={classes['image-group']}>
							<ImageC className={classes['image-1']} src="/images/maintenance.png" />
							<ImageC className={classes['image-2']} src="/images/maintenance-2.png" />
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default Maintenance;
